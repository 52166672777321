<template>
	<div>

	<el-container class="data_skc">

		<el-aside class="aside-style" width="200px">
			<common-nav selec="3"></common-nav>
		</el-aside>
		
		<el-container>

			<el-header class="common_header">
				<common-header></common-header>
			</el-header>
			
			<el-main>

				<el-tabs  v-model="activeTab" @tab-click="handleTabClick">

					<el-tab-pane label="首屏幻灯片" name="mainpage_banner">
						<el-row :gutter="24" class="page_bar">
							<el-col :span="18">
								<el-button size="small" type="success"  icon="el-icon-plus" @click="showAddBanner"  >新建幻灯片</el-button>
							</el-col>
						</el-row>
						<el-table v-loading="loading" :data="bannerTableData" size="medium" stripe highlight-current-row>					
							<el-table-column prop="id" label="编号" width="80" ></el-table-column>
							<el-table-column prop="status" label="状态" >
								<template slot-scope="scope">
									<label v-if="scope.row.status == 1" style="color:green">展示中</label>
									<label v-if="scope.row.status == 0" style="color:red">下架</label>
								</template>
							</el-table-column>
							<el-table-column label="配图" width="270">
								<template slot-scope="scope">
								<el-image fit="cover" class="pic_images" :src="'https://www.xjjgweiguang.com/uploads/' + scope.row.pic_url"></el-image>
								</template>
							</el-table-column>
							<el-table-column prop="name" label="幻灯片名称" ></el-table-column>
							<!-- <el-table-column prop="sort" label="排序" ></el-table-column> -->

							<el-table-column label="操作" width="170">
								<template slot-scope="scope">
									<el-button @click="handleDelBanner(scope.row.id)" type="danger" size="mini" plain>删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-tab-pane>

					<el-tab-pane label="首屏九宫格" name="mainpage_button">
						<div class="jgg_main" >
							<div class="jgg_main_l">

								<div v-for="item in nineTableData" :key="item.btn_id" class="mbtn" @click="showSetBtn(item.btn_id)">
									<img :src="'https://www.xjjgweiguang.com/uploads/'+item.icon_url">
									<div class="mbtn_label">{{item.name}}</div>
								</div>

								<div class="clear"></div>
							</div>
							
							<div class="jgg_main_r">
								<div class="set_jgg_panel" v-if="showBigBtnPanel">
									<div class="jggxir_row">
										按钮文字：
										<el-input v-model="ninebtn_txt" maxlength="30" placeholder="请输入内容"></el-input>
									</div>
									<div class="jggxir_row">
										按钮图片：
										<div>
											<el-image style="float:left; width: 30%;" fit="cover" class="icon_show_pic" :src="'https://www.xjjgweiguang.com/uploads/'+ninebtn_icon"></el-image>
											<div style="float:left;  width: 60%;" >
												<el-upload
													class="upload-demo"
													action="https://www.xjjgweiguang.com/kindergarten/index/postsImages"
													:limit="1"
													:on-success="handleSaveIconUrl"
													:show-file-list="false" 
													:file-list="iconFileList">
													<el-button size="mini" type="primary">选择新图</el-button>
													<div slot="tip" class="el-upload__tip">宽100px 高100px，建议透明的png或gif图片</div>
												</el-upload>

											</div>
											
											<div class="clear"></div>
										</div>
									</div>
									<div class="jggxir_row">
										按钮链接：
										<div class="linktype_row">
											<el-radio-group v-model="ninebtn_linktype" @change="handeChangeLink">
												<el-radio label="1">链接到栏目</el-radio>
												<el-radio label="2">链接到课件详情</el-radio>
											</el-radio-group>
										</div>
										<div v-if="handleShowClassLink">
											<el-select v-model="ninebtn_link_class_id"  placeholder="请选择" size="small" style="width:100%">
												<el-option
												v-for="item in classData"
												:key="item.id" 
												:label="item.name" 
												:value="item.id">
												</el-option>
											</el-select>
										</div>
										<div v-if="handleShowCaseLink">
											<el-input v-model="ninebtn_link_case_id"  size="small" placeholder="请输入内容"></el-input>
											<div class="showtipsx">请将要链接到的课件ID粘贴进输入框</div>
										</div>
									</div>
									<el-button type="success"  @click="hadleSaveNineBtnSet">保存修改</el-button>
								</div>
							</div>
							<div class="clear"></div>
						</div>
			
					</el-tab-pane>

					<!-- <el-tab-pane label="首屏推荐" name="mainpage_recommend">mainpage_recommend</el-tab-pane> -->

					<el-tab-pane label="界面风格" name="page_style">

						<div class="set_minipage">

							<el-row :gutter="24" >
								<el-col :span="24">
									当前小程序中设置：
								</el-col>
							</el-row>

							<el-row :gutter="24" class="view_cover_pic" >
								<el-col :span="20">
									<el-image fit="cover" class="mini_images" :src="'https://www.xjjgweiguang.com'+minibg_pic"></el-image>
								</el-col>
							</el-row>

							<el-row :gutter="24" >
								<el-col :span="19">
									<el-upload
										class="upload-demo"
										action="https://www.xjjgweiguang.com/kindergarten/index/postsImages"
										:limit="1"
										:on-success="handleSaveMiniBgUrl"
										:show-file-list="false" 
										:file-list="miniBgFileList">
										<el-button  type="success">选择新的背景图</el-button>
										<!-- <div slot="tip" class="el-upload__tip">宽100px 高100px，建议透明的png或gif图片</div> -->
									</el-upload>
									
								</el-col>
								<el-col :span="5">
									<el-button  type="warning" plain @click="handleResetMiniBgPic">恢复为默认</el-button>
								</el-col>
							</el-row>

							<div class="setmini_tips">图片要求jpg格式、宽2160px 高932px，不要超过300kb</div>

						</div>

					</el-tab-pane>

					<!-- <el-tab-pane label="价格及支付管理" name="mian_price">mian_price</el-tab-pane> -->

				</el-tabs>

				</el-main>

			<el-footer> copyright &copy; xjjgweiguang.com </el-footer>
		</el-container>
	</el-container>

	<el-dialog
		title="新建幻灯片"
		:visible.sync="showAddBannerPic"
		width="40%"
		:close-on-click-modal="false">
		<el-row class="row_spac">
			<el-col :span="20"><span class="red_dot">*</span>幻灯片名称：<el-input maxlength="100" v-model="newbanner_txt" placeholder="请输入内容" ></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="20"><span class="red_dot">*</span>幻灯片链接（输入课件id）：<el-input maxlength="200" v-model="newbanner_link" placeholder="请输入内容" ></el-input></el-col>
		</el-row>
		<el-row class="row_spac">
			<el-col :span="20">
			<el-upload
				class="upload-demo"
				action="https://www.xjjgweiguang.com/kindergarten/index/postsImages"
				:limit="1"
				:show-file-list="false" 
				:on-success="handleSaveNewBannerUrl"
				:file-list="bannerFileList">
				<el-button  type="success">选择图片</el-button>
				<div slot="tip" class="el-upload__tip">宽680px 高380px，建议png或jpg图片</div>
			</el-upload>
			<el-image fit="cover" class="bannerpic_images" :src="'https://www.xjjgweiguang.com/uploads/' + newBannerUrl"></el-image>
			</el-col>
		</el-row>
		
		<span slot="footer" class="dialog-footer">
			<el-button @click="showAddBannerPic = false">取 消</el-button>
			<el-button type="primary" @click="handleSaveNewBannerPic">保 存</el-button>
		</span>
	</el-dialog>

	</div>
</template>

<script>

import CommonNav from "@/components/Nav.vue";
import CommonHeader from "@/components/Header.vue";

import * as MN from '@/api/common'
import { MenuItem } from 'element-ui';

export default {
    name: 'crm', 
	props:['selec'],
	data() {
		return {
			newbanner_txt:'',
			newbanner_link:'',
			newBannerUrl:'',
			bannerFileList:[],
			showAddBannerPic:false,
			minibg_pic:'/xcx_res/p_bg.jpg',
			miniBgFileList:[],
			iconFileList:[],
			classData:[],
			handleShowClassLink:true,
			handleShowCaseLink:false,
			ninebtn_id:'',
			ninebtn_linktype:1,
			ninebtn_link_class_id:'',
			ninebtn_link_case_id:'',
			ninebtn_icon:'',
			ninebtn_txt:'',
			showBigBtnPanel:false,
			nineTableData:[],
			bannerTableData:[],
			new_type:'',
			select_power:1,
			powerData:[
				{value:1,label: '管理员'},
				{value:2,label: '普通用户'}
			],
			dialogAddType:false,
			dialogAddTypeTitle:'',
			new_type_name:'',
			dishTypeTableData:[],
			foodTypeTableData:[],
			foodWeightTableData:[],
			new_kindergarten_name:'',
			new_kindergarten_address:'',
			new_kindergarten_people:'',
			new_kindergarten_phone:'',
			new_kindergarten_userpass:'',

			new_supplier_company:'',
			new_supplier_address:'',
			new_supplier_name:'',
			new_supplier_phone:'',
			new_supplier_userpass:'',
			new_supplier_manage_dish:true,
			new_supplier_manage_food:true,

			new_admin_phone:'',
			new_admin_name:'',
			new_admin_userpass:'',

			activeTab:'mainpage_banner',
			searchValue:'',
			loading:true,
			boxshow: false, 
			curTipsID: '', 
			kindergartenTableData: [],
			supplierTableData: [],
			adminTableData: [],
			dialogAddKindergarten:false,
			dialogAddSupplier:false,
			dialogAddAdmin:false
		}
	},
	components: {
		CommonNav,
		CommonHeader
	},

	created() {
		this.initData()
	},

	mounted() {
		// this.initData('mainpage_banner')
	},

	methods: {
		handleSaveNewBannerPic(){
			console.log(this.newBannerUrl);
			if( !this.newBannerUrl ){
				this.$message.warning('请选择幻灯片图片');
				return
			}

			if( !this.newbanner_txt ){
				this.$message.warning('幻灯片标题不能为空');
				return
			}

			if( !this.newbanner_link ){
				this.$message.warning('请填幻灯片链接');
				return
			}

			let me = this;
			let post={
				banner_txt:this.newbanner_txt,
				banner_url:this.newBannerUrl,
				banner_link:this.newbanner_link,
			}
			MN.setNewBannerPic(post).then(res => {
				if( res == 'set_ok' ){
					me.$message.success('保存成功，已生效');
					me.showAddBannerPic = false;
					me.initData();
				}
			})

		},
		handleSaveNewBannerUrl(response, file, fileList){
			console.log("response", response);
			console.log("file", file);
        	console.log("fileList", fileList);
			this.newBannerUrl = response.image_url;
		},
		handleDelBanner(banner_id){
			console.log(banner_id)
			let me = this;
			this.$confirm("确认删除这张幻灯片图片吗？", '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let post={
					banner_id:banner_id,
				}
				MN.delBannerPic(post).then(res => {
					me.$message.success('删除成功，已生效');
					me.initData();
				})
			}).catch(() => {
        
			});
		},
		handleResetMiniBgPic(){
			let me = this;
			this.$confirm("确认改为默认的背景图吗？", '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				MN.resetMiniBgPic().then(res => {
					me.$message.success('保存成功，已生效');
					me.initData();
				})
			}).catch(() => {
        
			});
		},
		handleSaveMiniBgUrl(response, file, fileList) {
			console.log("response", response);
			console.log("file", file);
        	console.log("fileList", fileList);
			this.minibg_pic = response.image_url;
			let me = this;
			let post={
				bg_url:this.minibg_pic,
			}
			MN.setMiniBgPic(post).then(res => {
				if( res == 'set_ok' ){
					me.$message.success('保存成功，已生效');
					me.initData();
				}
			})
		},
		handleSaveIconUrl(response, file, fileList) {
			console.log("response", response);
			console.log("file", file);
        	console.log("fileList", fileList);
			this.ninebtn_icon = response.image_url;
		},
		hadleSaveNineBtnSet(){
			if( !this.ninebtn_txt ){
				this.$message.warning('按钮文字不能为空');
				return
			}
			
			if( !this.ninebtn_link_case_id && !this.ninebtn_link_class_id ){
				this.$message.warning('请选择按钮链接');
				return
			}


			if( this.ninebtn_linktype == '1' && !this.ninebtn_link_class_id ){
				this.$message.warning('请选择连接到的栏目');
				return
			}

			if( this.ninebtn_linktype == '2' && !this.ninebtn_link_case_id ){
				this.$message.warning('请选择链接到的课件');
				return
			}

			console.log("ninebtn_id",this.ninebtn_id);
			console.log("ninebtn_linktype",this.ninebtn_linktype);
			console.log("ninebtn_link_class_id",this.ninebtn_link_class_id);
			console.log("ninebtn_link_case_id",this.ninebtn_link_case_id);
			console.log("ninebtn_icon",this.ninebtn_icon);
			console.log("ninebtn_txt",this.ninebtn_txt);

			// let getClassID = '';
			// this.classData.forEach((item,index)=>{
			// 	if ( this.ninebtn_link_class_id == index ){
			// 		getClassID = item.id;
			// 	}
			// })

			let me = this;
			let post={
				btn_id:this.ninebtn_id,
				btn_icon:this.ninebtn_icon,
				btn_linktype:this.ninebtn_linktype,
				btn_link_class_id:this.ninebtn_link_class_id,
				btn_icon_case_id:this.ninebtn_link_case_id,
				btn_txt:this.ninebtn_txt
			}
			MN.setNineBtnDataSave(post).then(res => {
				if( res == 'set_ok' ){
					me.$message.success('保存成功');
					me.initData();
					me.showBigBtnPanel = false;
				}
			})



		},
		handeChangeLink(e){
			
			this.ninebtn_linktype = e;
			if ( e == 1 ){
				this.handleShowCaseLink = false;
				this.handleShowClassLink = true;
				this.ninebtn_link_case_id ='';
				this.ninebtn_link_class_id = '';
			}
			if ( e == 2 ){
				this.handleShowCaseLink = true ;
				this.handleShowClassLink = false;
				this.ninebtn_link_case_id ='';
				this.ninebtn_link_class_id = '';
			}
			console.log(this.ninebtn_link_class_id);
		},
		showAddBanner(){
			this.newBannerUrl = '';
			this.newbanner_txt = '';
			this.newbanner_link = '';
			this.showAddBannerPic = true;
		},
		showSetBtn(btnid){
			console.log(btnid);
			let me = this;
			this.nineTableData.forEach(item => {
				me.showBigBtnPanel = true;

				if ( item.btn_id == btnid ){
					me.ninebtn_id = item.btn_id;
					me.ninebtn_icon = item.icon_url;
					me.ninebtn_txt = item.name;
					me.ninebtn_linktype = item.link_type;
					if (item.link_type=='1'){
						
						me.handleShowClassLink = true;
						me.handleShowCaseLink = false;
						me.ninebtn_link_class_id = item.link;
						// me.ninebtn_link_class_id = item.link;
						// me.classData.forEach((cix,index) => {
						// 	if ( cix.id == item.link ){
						// 		me.ninebtn_link_class_id = index;
						// 	}
						// })
					}
					if (item.link_type=='2'){
						me.ninebtn_link_case_id = item.link;
						me.handleShowClassLink = false ;
						me.handleShowCaseLink = true;
					}
					
					console.log(item)
				}
			});
			// me.$forceUpdate();
		},
		handleTabClick(){
			this.initData()
		},
		initData(){
			let me = this;
			this.loading = true
			MN.getWechatMiniData().then(res => {
				me.bannerTableData=res.bannerlist;
				me.nineTableData = res.ninelist;
				me.minibg_pic = res.minibg_pic;
				me.loading=false;
			})

			MN.getFirstCaseClassData().then(res => {
				me.classData=res;
			})

		},
	}
}
</script>

<style scoped>

.bannerpic_images{ width: 280px; height: 156px; }

.showtipsx{ font-size: 13px; padding: 4px 0 0 0; color: #9d9d9d;  }
.linktype_row{ margin: 5px 0 3px 0px; }

.jggxir_row{ margin: 0 0 20px 0; padding-top:4px; }

.icon_show_pic{ width: 100px; height: 100px; margin-right: 10px; border: 1px solid #cccccc; }
.view_cover_pic{ margin: 5px 0 20px 0; }

.setmini_tips{ font-size: 14px; margin: 8px 0 0 0; color: #b94646; }
.set_minipage{ width: 600px; }
.mini_images{ width: 600px; border: 1px solid #d1d1d1;border-radius: 10px; }

.pic_images{ width:205px; height: 108px; }
.jgg_main{ background:#f8f8f8; padding: 2%; border-radius: 10px; min-width: 900px; }
.jgg_main_l{ float: left;  width: 420px; border-right: 2px solid #d1d1d1; }
.jgg_main_r{ float: left; padding: 2%; width: 350px; }
.set_jgg_panel{ background-color: #ffffff; padding: 5%; width:90%; border-radius: 10px; }

.mbtn img{ position: absolute; width: 64px; left: 0; right: 0; margin: 0 auto; top:20px }
.mbtn{ cursor: pointer; float: left; position: relative; width: 120px; height: 120px; margin: 0 10px 20px 0; background-color: #ffffff; border-radius: 10px; }
.clear{ clear: both; }
.mbtn_label{ position: absolute; color: #383838; font-size: 15px; left: 0; bottom: 15px; width: 100%; text-align:center; font-weight: bolder; }

.row_spac{ margin-bottom: 20px; }

.risl{ color: #e23737; }

.red_dot{ color: #e23737; font-weight: bold; }

/* ::v-deep .el-card__body, .el-main{
	padding: 0;
} */
</style>
